<template>
    <div 
        class="report-card" 
        @click.prevent="$router.push({
            name: 'tariffs-type',
            params: {
                id: getTypeId
            }
        })"
    >
        <h4 class="report-card-title">{{ title }}</h4>
        <div class="mt-4">
            <div class="relative">
                <span class="report-card-icon-box"></span>
                <span class="report-card-icon">
                    <Icon :name="icon" width="61" height="61" :color="$attrs.iconColor" :textColor="$attrs.iconTextColor" />
                </span>
            </div>
        </div>
    </div>
</template>

<script>

    import { computed } from 'vue'
    import { useStore } from 'vuex'

    export default {
        
        inheritAttributes: false,

        props: {

            title: {
                required: true,
                type: String
            },
            icon: {
                required: true,
                type: String
            },
           
        },

        setup(props) {

            const store = useStore()
            const types = computed(() => store.getters['tariffs/types'])

            const getTypeId = computed(() => {
                const titleKey = props.title.split(" ")[1].toLowerCase();
                return types.value.find(type => type.name.toLowerCase() === titleKey)?.id
            }) 

            return {
                getTypeId
            }

        },
        
        

    }
</script>

<style lang="scss" scoped>

</style>