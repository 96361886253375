<template>
  <Layout mainClasses="pt-1 px-5 bg-app-gray">
    <h1 class="text-left my-5 text-3xl font-body">Prices & Tariffs</h1>

    <div class="mr-5 my-5 grid grid-cols-2 gap-4 relative">
      <Card
        v-for="data in TariffTypesBoxes"
        :key="data.title"
        :title="data.title"
        icon="tariffs"
        iconColor="transparent"
        iconTextColor="#7f7b7b"
      />
    </div>
  </Layout>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import Layout from "../components/home/Layout/Index";
import useTariffs from "@/modules/widget/composables/useTariffs";
import Card from "../components/pricesAndTariffs/Card";

const TariffTypesBoxes = computed(() => {
  return [
    {
      title: "Tariff SMS",
    },
    {
      title: "Tariff Voice",
    },
  ];
});
</script>
